<template>
  <!-- 业务对象树 -->
  <div>
    <a-row class="object-tree-search">
      <a-col :span="24" class="search-header">
        <a-input-search :placeholder="placeHolder" @change="handleSearchObject" class="search-input" />
        <a-radio-group class="search-btn" button-style="solid" v-if="showFilterBtn" v-model="isAll" @change="handleToggle">
          <a-radio-button :value="true"> 全部 </a-radio-button>
          <a-radio-button :value="false"> 在线 </a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row class="object-tree-content">
      <a-col :span="24">
        <!-- v-model="checkedKeys" -->
        <a-tree
          v-if="myTreeData && myTreeData.length > 0"
          v-model="currentCheckedKeys"
          checkable
          :tree-data="myTreeData"
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :default-expand-all="true"
          :selected-keys="selectedKeys"
          :replace-fields="replaceFields"
          @expand="onExpand"
          @select="onSelect"
          @check="onCheck"
          showIcon
        >
          <template slot="title" slot-scope="{ label, id }">
            <div class="tree-label">
              <span v-if="label.indexOf(searchValue) > -1">
                {{ label.substr(0, label.indexOf(searchValue)) }}
                <span class="tree-seach-title">{{ searchValue }}</span>
                {{ label.substr(label.indexOf(searchValue) + searchValue.length) }}
              </span>
              <span v-else>{{ label }}</span>
              <img :src="onlineObj[id] === 1 ? onlineImgSrc : offlineImgSrc" :title="onlineObj[id] ? onlineHint : offlineHint" v-if="[0, 1].indexOf(onlineObj[id]) >= 0" />
            </div>
          </template>
        </a-tree>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import allIcons from '@/core/icons'
import { Tree } from 'ant-design-vue'

const getParentKey = (id, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.id === id)) {
        parentKey = node.id
      } else if (getParentKey(id, node.children)) {
        parentKey = getParentKey(id, node.children)
      }
    }
  }
  return parentKey
}
export default {
  name: 'ObjectTree',
  props: {
    cacheKey: {
      type: String,
      required: true
    },
    placeHolder: {
      type: String,
      required: true
    },
    treeData: {
      type: Array,
      required: true
    },
    treeSelectedKey: {
      type: String,
      default: () => {}
    },
    objectTitle: {
      type: String,
      required: true
    },
    objectIcon: {
      type: String,
      default: () => {}
    },
    idStartWith: {
      type: String,
      default: 't-1-'
    },
    // 初始化选中的key
    initSelectedKey: {
      type: String,
      default: ''
    },
    checkedList: {
      type: Array,
      default: () => {}
    },
    onlineImgSrc: {
      type: String,
      required: true
    },
    offlineImgSrc: {
      type: String,
      required: true
    },
    onlineHint: {
      type: String,
      required: true
    },
    offlineHint: {
      type: String,
      required: true
    },
    showFilterBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ATree: Tree
  },
  beforeDestroy() {},
  data() {
    return {
      // vehicleAutoIcon
      objectAutoIcon: allIcons[this.objectIcon],
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      objectNodes: [],
      searchValue: '',
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      myTreeData: [],
      currentCheckedKeys: [],
      storeKey: '',
      treeIds: [],
      // 上一次选中的Key列表
      lastCheckedKeys: [],
      // 树键值对
      keyValueArray: [],
      onlineObj: {},
      // 是否为全部
      isAll: true
    }
  },
  filters: {},
  created() {},
  mounted() {
    console.log('tree-checked-list-2', this.checkedList)
    this.setStoreKey()
    if (this.treeData && this.treeData.length > 0) {
      this.doInitTree(this.treeData)
    }
  },
  computed: {},
  watch: {
    checkedKeys(val) {
      // console.log('onCheck', val)
    },
    treeData(val) {
      this.doInitTree(val)
    },
    treeSelectedKey(newVal, oldVal) {
      if (newVal) {
        // console.log('设置树选中')
        this.selectedKeys = [this.idStartWith + newVal]
      }
    },
    // 选中列表外部发生变化
    checkedList(newVal, oldVal) {
      console.log('tree-checked-list', newVal)
      if (!newVal || newVal.length === 0) {
        this.currentCheckedKeys = []
        return
      }
      this.currentCheckedKeys = newVal.map((p) => this.idStartWith + p)
    },
    isChecked() {
      return !this.isAll
    }
  },
  methods: {
    doInitTree(val) {
      if (val.length > 0) {
        // 初始化打开第一级子节点
        if (val[0]['children']) {
          val[0]['children'].forEach((child) => {
            this.expandedKeys.push(child['id'])
          })
        }
      }
      this.myTreeData = this.treeDataFormat(val)
      // console.log('this.myTreeData:', this.myTreeData)
      this.treeIds = []
      this.getTreeIds(val)
      this.initTree()
    },
    handleToggle(e) {
      console.log('handleToggle', e)
      this.isAll = e.target.value
      this.$emit('filter', this.isAll)
    },
    setStoreKey() {
      const hostname = location.hostname
      let tenantCode = 'yw'
      if (hostname.split('.').length === 4) {
        tenantCode = hostname.split('.')[0]
      }
      this.storeKey = tenantCode + '-' + this.cacheKey + '-lastCheckedInfo'
    },
    // 设置当前选中的Key
    setSelectedKey(key) {
      this.selectedKeys = [this.idStartWith + key]
    },
    saveCheckData() {
      // 记忆上一次退出的数据
      if (!this.cacheKey) {
        return
      }
      const lastCheckedInfo = {
        currentCheckedKeys: [],
        selectedKeys: [],
        expandedKeys: []
      }
      if (this.currentCheckedKeys && this.currentCheckedKeys.length > 0) {
        const currentCheckedKeys = [...this.currentCheckedKeys]
        for (let n = currentCheckedKeys.length, k = n - 1; k >= 0; k--) {
          if (currentCheckedKeys[k].indexOf('t-1') < 0) {
            currentCheckedKeys.splice(k, 1)
          }
        }
        lastCheckedInfo.currentCheckedKeys = currentCheckedKeys
      }
      if (this.selectedKeys && this.selectedKeys.length > 0) {
        lastCheckedInfo.selectedKeys = this.selectedKeys
      }
      if (this.expandedKeys && this.expandedKeys.length > 0) {
        lastCheckedInfo.expandedKeys = this.expandedKeys
      }
      localStorage.setItem(this.storeKey, JSON.stringify(lastCheckedInfo))
    },
    getTreeIds(treeData) {
      if (treeData && treeData.length > 0) {
        treeData.forEach((p) => {
          this.treeIds.push(p.id)
          if (p.children && p.children.length > 0) {
            this.getTreeIds(p.children)
          }
        })
      }
    },
    initTree() {
      console.log('initTree')
      if (!this.cacheKey) {
        if (this.checkedList) {
          this.currentCheckedKeys = this.checkedList.map((p) => this.idStartWith + p)
        }
        return
      }
      let lastCheckedInfo = localStorage.getItem(this.storeKey)
      if (lastCheckedInfo) {
        lastCheckedInfo = JSON.parse(lastCheckedInfo)
        if (lastCheckedInfo) {
          let currentCheckedKeys = lastCheckedInfo.currentCheckedKeys
          let selectedKeys = lastCheckedInfo.selectedKeys
          let expandedKeys = lastCheckedInfo.expandedKeys
          // 重复代码 todo
          // 获取已选择的设备Keys
          currentCheckedKeys = this.resetCacheKeys(currentCheckedKeys)
          selectedKeys = this.resetCacheKeys(selectedKeys)
          expandedKeys = this.resetCacheKeys(expandedKeys)

          if (this.initSelectedKey) {
            const key = this.idStartWith + this.initSelectedKey
            const index = selectedKeys.indexOf(key)
            if (index > 0) {
              // 不在第一条交换位置
              const firstKey = selectedKeys[0]
              selectedKeys[0] = key
              selectedKeys[index] = firstKey
            } else if (index < 0) {
              // 插入第一条位置
              selectedKeys.unshift(key)
            }
            if (currentCheckedKeys.indexOf(key) < 0) {
              currentCheckedKeys.push(key)
            }
          }

          const tempCheckedKeys = currentCheckedKeys
            .map((id) => {
              if (id.startsWith(this.idStartWith)) {
                return id.substr(4)
              }
            })
            .filter((id) => id)
          // 增加校验缓存的信息在当前TreeData是否都存在 todo...
          // 单击事件,传参：先是当前选中的设备Id，然后是当前已打勾的设备列表
          this.currentCheckedKeys = currentCheckedKeys
          this.selectedKeys = selectedKeys
          if (expandedKeys.length > 0) {
            this.expandedKeys = expandedKeys
          }
          setTimeout(() => {
            const isSameList = this.checkIsSameList(tempCheckedKeys)
            this.$emit('select', this.getObjectId(this.selectedKeys[0]), tempCheckedKeys, isSameList)
          }, 200)
        }
      } else if (this.initSelectedKey) {
        // 存在初始化key
        this.selectedKeys = [this.idStartWith + this.initSelectedKey]
        this.currentCheckedKeys = this.selectedKeys
        this.$emit('select', this.getObjectId(this.selectedKeys[0]), [this.initSelectedKey], false)
      }
    },
    resetCacheKeys(arr) {
      if (!arr) {
        return []
      }
      for (let k = arr.length - 1; k >= 0; k--) {
        if (this.treeIds.indexOf(arr[k]) < 0) {
          arr.splice(k, 1)
        }
      }
      return Array.from(new Set(arr))
    },
    // 根据节点id获取对象Id
    getObjectId(nodeId) {
      if (!nodeId) {
        return null
      }
      if (nodeId.startsWith(this.idStartWith)) {
        return nodeId.substr(4)
      } else {
        return null
      }
    },
    // 重构车辆树，判断增加slots
    treeDataFormat(treeJson) {
      if (!Array.isArray(treeJson) || treeJson.length <= 0) {
        return []
      }
      const res = []
      treeJson.forEach((item, i) => {
        if (item.children && item.children.length > 0) {
          res.push({
            id: item.id,
            label: item.label,
            children: this.treeDataFormat(item.children)
          })
        } else {
          if (item.id.indexOf('t-1') >= 0) {
            this.onlineObj[item.id] = item.online
          }
          const arr = item.id.split('-')
          const vObj = {
            id: item.id,
            label: item.label
          }
          if (arr[1] === '1') {
            vObj['slots'] = {
              icon: 'auto'
            }
          }
          res.push(vObj)
        }
      })
      return res
    },
    // 获取所有对象节点
    getAllObjectNode(nodes) {
      if (!nodes || nodes.length === 0) {
        return []
      }
      nodes.forEach((node) => {
        this.objectNodes.push({ id: node.id, label: node.label })
        return this.getAllObjectNode(node.children)
      })
    },
    // 筛选节点
    handleSearchObject(e) {
      // console.log('this.treeData:', this.treeData)
      this.getAllObjectNode(this.treeData)
      const value = e.target.value
      const gData = this.treeData
      const expandedKeys = this.objectNodes
        .map((item) => {
          if (item.label.indexOf(value) > -1) {
            return getParentKey(item.id, gData)
          }
          return null
        })
        .filter((item, i, self) => item && self.indexOf(item) === i)
      Object.assign(this, {
        expandedKeys: expandedKeys,
        searchValue: value,
        autoExpandParent: true
      })
      this.objectNodes = []
    },
    // 递归取消节点选中
    doUnselectNode(node) {
      // 先取消当前节点选中
      if (node.checked && node.dataRef) {
        const key = node.dataRef.id
        const index = this.currentCheckedKeys.indexOf(key)
        this.currentCheckedKeys.splice(index, 1)
        if (node.$parent) {
          this.doUnselectNode(node.$parent)
        }
      }
    },
    // 递归取消子节点选中
    doUnselectChildNode(children) {
      children.forEach((p) => {
        const index = this.currentCheckedKeys.indexOf(p.id)
        if (index >= 0) {
          this.currentCheckedKeys.splice(index, 1)
        }
        if (p.children && p.children.length > 0) {
          this.doUnselectChildNode(p.children)
        }
      })
    },
    // 递归选中父节点
    doCheckNode(node) {
      // 如果当前节点已选中，则退出
      if (node.checked) {
        return
      }
      if (node.dataRef) {
        const key = node.dataRef.id
        // 添加当前节点KEY
        if (this.currentCheckedKeys.indexOf(key) < 0) {
          this.currentCheckedKeys.push(key)
        }
        // 递归选中父节点
        if (node.$parent) {
          const pNode = node.$parent
          let isSelectedAll = true
          if (pNode.dataRef) {
            const childNodes = pNode.dataRef.children
            if (childNodes && childNodes.length > 0) {
              childNodes.forEach((p) => {
                if (this.currentCheckedKeys.indexOf(p.id) < 0) {
                  isSelectedAll = false
                }
              })
            }
            if (isSelectedAll) {
              // 如果父节点下的所有节点都选中，则要选中父节点
              this.doCheckNode(pNode)
            }
          }
        }
      }
    },
    // 节点单击事件: 显示当前设备信息，并在地图上高亮显示；单击选中时自动打勾
    onSelect(selectedKeys, info) {
      // console.log('key', info.node.dataRef.id)
      // console.log('onSelect info:', info)
      // console.log('onSelect selectedKeys:', selectedKeys)
      // console.log('currentCheckedKeys', this.currentCheckedKeys)
      console.log(`selectedKeys=${selectedKeys}`)
      const checkedLabels = []
      if (selectedKeys.length === 0) {
        // 取消选中节点不能自动反选
        // 取消选中
        // this.doUnselectNode(info.node)
        // // 取消选中子节点
        // const node = info.node
        // if (node._props && node._props.dataRef) {
        //   const children = node._props.dataRef.children
        //   if (children && children.length > 0) {
        //     this.doUnselectChildNode(children)
        //   }
        // }
      } else {
        // 要求递归选中父节点
        this.doCheckNode(info.node)
        // 要求递归选中子节点
        const selectedNodes = info.selectedNodes
        if (selectedNodes.length > 0) {
          const node = selectedNodes[0]
          if (node.data && node.data.props && node.data.props.dataRef) {
            const dataRef = node.data.props.dataRef
            if (dataRef.children && dataRef.children.length > 0) {
              this.doCheckChildNode(dataRef.children)
            }
          }
        }
      }
      this.selectedKeys = selectedKeys
      // 获取已选择的设备Keys
      const tempCheckedKeys = this.currentCheckedKeys
        .map((id) => {
          if (id.startsWith(this.idStartWith)) {
            return id.substr(4)
          }
        })
        .filter((id) => id)
      // 如果当前打勾只有一条，则默认选中
      if (tempCheckedKeys.length > 0 && selectedKeys.length === 0) {
        this.selectedKeys.splice(0, 0, this.idStartWith + tempCheckedKeys[tempCheckedKeys.length - 1])
      }
      const isSameList = this.checkIsSameList(tempCheckedKeys)
      // 单击事件,传参：先是当前选中的设备Id，然后是当前已打勾的设备列表
      this.$emit('select', this.getObjectId(selectedKeys[0]), tempCheckedKeys, isSameList, checkedLabels)
      this.saveCheckData()
    },

    // 判断选中列表是否发生变化
    checkIsSameList(newIds) {
      this.lastCheckedKeys.sort()
      newIds.sort()
      const result = this.lastCheckedKeys.toString() === newIds.toString()
      this.lastCheckedKeys = newIds
      return result
    },

    // 节点多选事件：在地图上显示多个设备的位置分布
    onCheck(checkedKeys, e) {
      // console.log('onCheck', checkedKeys)
      // console.log('currentCheckedKeys', this.currentCheckedKeys)
      // 删除已选择中但又取消打勾的选项
      const checkedLabels = []
      checkedKeys.forEach((p) => {
        if (p.startsWith(this.idStartWith)) {
          checkedLabels.push(this.getCheckedLabel(e, p))
        }
      })
      for (let n = this.selectedKeys.length, k = n - 1; k >= 0; k--) {
        const key = this.selectedKeys[k]
        if (checkedKeys.indexOf(key) < 0) {
          this.selectedKeys.splice(k, 1)
        }
      }
      this.checkedKeys = checkedKeys
        .map((id) => {
          if (id.startsWith(this.idStartWith)) {
            return id.substr(4)
          }
        })
        .filter((id) => id)
      // 如果当前打勾只有一条，则默认选中
      if (this.checkedKeys.length > 0) {
        this.selectedKeys.splice(0, 0, this.idStartWith + this.checkedKeys[this.checkedKeys.length - 1])
      }
      const tempCheckedKeys = [...this.checkedKeys]
      const isSameList = this.checkIsSameList(tempCheckedKeys)
      // 打勾发送事件，传参：先是当前已打勾的设备列表，然后是当前选中的设备
      this.$emit('check', this.checkedKeys, this.getObjectId(this.selectedKeys[0]), isSameList, checkedLabels)
      this.saveCheckData()
    },
    getCheckedLabel(e, key) {
      const node = e.checkedNodes.find((p) => p.key === key)
      if (node && node.data && node.data.props) {
        return node.data.props.label
      }
      return ''
    },

    // 递归添加子节点
    doCheckChildNode(e) {
      e.forEach((p) => {
        if (this.currentCheckedKeys.indexOf(p.id) < 0) {
          this.currentCheckedKeys.push(p.id)
        }
        if (p.children && p.children.length > 0) {
          this.doCheckChildNode(p.children)
        }
      })
    },

    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    }
  }
}
</script>

<style lang="less">
.object-tree-search {
  height: 32px;
}
.object-tree-content {
  overflow: auto;
  height: calc(100% - 34px);
  margin-top: 5px;
}
.tree-seach-title {
  color: @danger;
}
</style>
<style lang="less" scoped>
.search-header {
  display: flex;
  align-items: center;
  /deep/ .search-input {
    flex: 1;
    input {
      font-size: 12px !important;
    }
  }
  .search-btn {
    // flex: 0 0 130px;
    margin-left: 10px;
  }
}

.tree-label {
  display: flex;
  align-items: center;
  img {
    margin-left: 5px;
    height: 16px;
    width: 16px;
  }
}
</style>
